import {
  ICustomerDto,
  FinscanInputDto,
  FinscanOutputDto,
  StatOutputDto,
  StatInputDto,
  ICustomerSearch,
} from 'types/client';
import axiosClient from './axiosClient';
import { IGedCatalogue } from 'types/ged';
import { FLAG_URL, BASE_URL_CUSTOMERS } from 'api/routes';
import { IPolicyLightDto } from 'types/policy';
import { ISearchParam, ISorting } from 'types/types';
import { ICustomerClaimsOutput, IFilter } from 'types/claims';
import { ICustomerReceiptsOutput } from 'types/receipts';
import { EsClient, EsSearchResponse } from 'types/es';

const customerApi = {
  async getCustomerByCustomerNumber(
    customerNumber: number
  ): Promise<ICustomerDto> {
    const url = `${BASE_URL_CUSTOMERS}/${customerNumber}`;

    return axiosClient.get(url);
  },

  async finscan(
    customerNumber: number,
    params: FinscanInputDto
  ): Promise<FinscanOutputDto> {
    const url = `${BASE_URL_CUSTOMERS}/${customerNumber}/finscan`;

    return axiosClient.post(url, params);
  },

  async getDocumentFromFlag(
    idObject: number,
    flag: string
  ): Promise<Array<IGedCatalogue>> {
    const url = `${BASE_URL_CUSTOMERS}${FLAG_URL}?idObject=${idObject}&flag=${flag}`;

    return axiosClient.get(url);
  },

  async getCustomerStats(
    customerNumber: number,
    params: StatInputDto
  ): Promise<StatOutputDto> {
    const url = `${BASE_URL_CUSTOMERS}/${customerNumber}/stats`;

    return axiosClient.post(url, params);
  },

  async getPoliciesByCustomerNumber(
    customerNumber: number,
    page: number,
    size: number
  ): Promise<IPolicyLightDto> {
    const url = `${BASE_URL_CUSTOMERS}/${customerNumber}/policies`;
    const correctPage = page + 1;

    return axiosClient.post(url, {
      page: correctPage,
      size,
    });
  },

  async getClaimsByCustomerNumber(
    customerNumber: number,
    page: number,
    size: number,
    filter?: IFilter
  ): Promise<ICustomerClaimsOutput> {
    const url = `${BASE_URL_CUSTOMERS}/${customerNumber}/claims`;
    const correctPage = page;

    return axiosClient.post(url, {
      filter: filter || {},
      page: correctPage,
      size,
    });
  },

  async getReceiptsByCustomerNumber(
    customerNumber: number,
    page: number,
    size: number,
    filter?: IFilter
  ): Promise<ICustomerReceiptsOutput> {
    const url = `${BASE_URL_CUSTOMERS}/${customerNumber}/receipts`;
    const correctPage = page;

    return axiosClient.post(url, {
      filter: filter || {},
      page: correctPage,
      size,
    });
  },

  async search(
    params: Array<ISearchParam>,
    page: number,
    size: number,
    sortings?: Array<ISorting>
  ): Promise<ICustomerSearch> {
    const url = `${BASE_URL_CUSTOMERS}/search`;
    const correctPage = page + 1;

    return axiosClient.post(url, {
      page: correctPage,
      size,
      sortings,
      params,
    });
  },

  async directEsSearch(
    txt: string,
    from: number,
    size: number
  ): Promise<EsSearchResponse<EsClient>> {
    const url = `http://localhost:9200/client-index/_search`;

    return axiosClient.post(url, {
      query: {
        multi_match: {
          query: txt,
          fields: ["firstName", "lastName", "address1", "address2", "city", "mobilePhone", "phone1", "phone2", "mail"]
        }
      },
      from,
      size
    });
  },

  async esSearch(txt: string): Promise<EsSearchResponse<EsClient>> {
    return axiosClient.get(`/es/client/search?txt=${txt}`);
  },

  async esSearchById(lienClient: Number): Promise<EsClient> {
    return axiosClient.get(`/es/client/${lienClient}`);
  },

  async osSearch(txt: string): Promise<EsClient[]> {
    return axiosClient.get(`/v2/search/client?txt=${txt}`);
  },

  async osSearchById(id: string): Promise<EsClient> {
    return axiosClient.get(`/v2/search/client/${id}`);
  },

};

export default customerApi;
