// URLs customer controller
export const BASE_URL_CUSTOMERS = '/v2/customers';
export const FLAG_URL = '/favorites/flags';
export const STATS_POLICY_URL = 'stats/policies';

// URLs policy controller
export const BASE_URL_POLICY = '/v2/police';

export const BASE_URL_USER_PARAMS = '/user-params';


