/** @jsxImportSource @emotion/react */

import { SearchMdIcon } from 'components/Icons/icons';
import { Button, IconButton, InputAdornment } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import InputBase from '@mui/material/InputBase';
import { useMemo, useState } from 'react';

import customerApi from 'api/customerApi';
import policyApi from 'api/policyApi';
import sinistreApi from 'api/sinistreApi';
import { EsClient } from 'types/es';
import { useHistory } from 'react-router-dom';
import styles from './style';
import configApi from 'api/configApi';
import { debounce } from "lodash";
import CircularProgress from '@mui/material/CircularProgress';


function getOptionLabel(option) {

  const showText = input => input ?? "";
  const showDashText = input => input ? " - " + input : "";

  if (option._class.endsWith("Client"))
    return showText(option.customerNumber) + showDashText(option.title) + ' ' + showText(option.lastName) + showDashText(option.mail);
  else if (option._class.endsWith("Police"))
    return showText(option.policy) + showDashText(option.subscriberTitle) + ' ' + showText(option.subscriberLastName) + ' ' + showText(option.subscriberFirstName) + showDashText(option.clePolice) + ' lienClient=' + option.lienClient;
  else if (option._class.endsWith("Sinistre"))
    return showText(option.nsin) + showDashText(option.nsinCompta) + showDashText(option.nomTie);
  else
    return "Entité inconnue";

}

export default function AutocompleteBar() {
  const history = useHistory();

  const [searchValue, setSearchValue] = useState<string>(null);
  const [loading, setLoading] = useState(false);

  const [options, setOptions] = useState([]);

  const minLengthSearchValue = 2;

  const fetchOptions = async (val) => {
    setSearchValue(val);
    if (val && val.length > minLengthSearchValue) {

      // const resp1: EsSearchResponse<EsClient> = await customerApi.esSearch(val);
      // let arr1 = resp1.searchHits.map(h => h.content);

      // const resp2: EsSearchResponse<EsPolice> = await policyApi.esSearch(val);
      // let arr2 = resp2.searchHits.map(h => h.content);

      // const resp3: EsSearchResponse<EsSinistre> = await sinistreApi.esSearch(val);
      // let arr3 = resp3.searchHits.map(h => h.content);

      setLoading(true);
      try {

        let arr1 = await customerApi.osSearch(val);
        let arr2 = await policyApi.osSearch(val);
        let arr3 = await sinistreApi.osSearch(val);
  
        setOptions([...arr1, ...arr2, ...arr3]);

      } catch (error) {
        console.error("Failed to fetch", error);
      } finally {
        setLoading(false);
      }
    }

  };

  // Memoized debounce function to prevent recreation on re-renders
  const debouncedFetch = useMemo(
    () => debounce((query) => fetchOptions(query), 1000), // delay in ms
    []
  );

  const handleInputChange = async (event) => {
    debouncedFetch(event.target.value);
  };

  const searchList = (searchEntity) => {
    if (searchValue) {
      history.push(`/${searchEntity.toLowerCase()}s?search=${searchValue}`);
      setSearchValue(null);
    }
  };

  const searchDetail = async (searchEntity, entityId, label) => {
    setSearchValue(null);
    // history.push(`/${searchEntity.toLowerCase()}s/${entityId}`);

    let queryStr: string = "";
    switch (searchEntity) {
      case 'Client':
        queryStr = '?numClient=' + entityId;
        break;
      case 'Police':
        let lienClientVal = label.substring(label.indexOf('lienClient=')).replace('lienClient=', '');
        let client: EsClient = await customerApi.osSearchById(lienClientVal);
        queryStr = '?numPolice=' + entityId + '&numClient=' + client?.customerNumber;
        break;
      case 'Sinistre':
        queryStr = '?nsin=' + entityId;
        break;
      default:
        break;
    }

    let config = await configApi.getConfig();
    window.open(config.data.NOVANET_URL + queryStr, "_blank");
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      // handleSearch();
    }
  };

  const renderChildren = (entity, children) => {
    return children.map(c => <li key={c.key} {...c.props} onClick={() => searchDetail(entity, c.key, c.props.children)}>{c.props.children}</li>);
  }

  return (

    <Autocomplete
      open={searchValue != null && searchValue.length > minLengthSearchValue}
      loading={loading}
      disablePortal
      id="autocomplete-bar"
      css={styles.textFieldWrapper}
      options={options}
      filterOptions={(x) => x}
      onInputChange={handleInputChange}
      renderInput={(params) =>
        <InputBase
          ref={params.InputProps.ref}
          fullWidth
          css={styles.textField}
          placeholder="Recherche rapide"
          // onChange={handleInputChange}
          endAdornment={
            <>
              {loading && <CircularProgress color="inherit" size={20} />}
              <InputAdornment position="end">
                <IconButton>
                  <SearchMdIcon />
                </IconButton>
              </InputAdornment>
            </>
          }
          inputProps={{ ...params.inputProps, onKeyDown: handleKeyDown }}
        />
      }

      getOptionKey={(option) => (option.customerNumber ? option.customerNumber : (option.policy ? option.policy : option.nsin))}

      getOptionLabel={getOptionLabel}

      groupBy={option => option._class}

      renderGroup={(params) => {
        let entity = params.group.substring(params.group.lastIndexOf('.') + 1);

        return <li key={params.key}>
          <div css={styles.groupHeader}>
            {entity}
            <Button size='small' css={styles.showMoreBtn}
              onClick={() => searchList(entity)}>
              show more...
            </Button>
          </div>
          <ul css={styles.groupItems}>
            {renderChildren(entity, params.children)}
          </ul>
        </li>
      }}

    />
  );
}