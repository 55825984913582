import axiosClient from './axiosClient';
import { BASE_URL_POLICY } from 'api/routes';
import { EsPolice, EsSearchResponse } from 'types/es';

const policyApi = {
  list: async () => {
    const url = `${BASE_URL_POLICY}/list`;
    return axiosClient.get(url);
  },


  async directEsSearch(
    txt: string,
    from: number,
    size: number
  ): Promise<EsSearchResponse<EsPolice>> {
    const url = `http://localhost:9200/police-index/_search`;

    return axiosClient.post(url, {
      query: {
        multi_match: {
          query: txt,
          fields: ["policy", "subscriberFirstName", "subscriberLastName", "subscriberAddress1", "subscriberAddress2", "subscriberCity", "subscriberZipCode", "clePolice", "garanties"]
        }
      },
      from,
      size
    });
  },

  async esSearch(txt: string): Promise<EsSearchResponse<EsPolice>> {
    return axiosClient.get(`/es/police/search?txt=${txt}`);
  },

  async osSearch(txt: string): Promise<EsPolice[]> {
    return axiosClient.get(`/v2/search/police?txt=${txt}`);
  },

};

export default policyApi;
